// Footer.js

import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => (
  <div className="footer-container">
    <div className="logo-container">
      <Link to="/">
        <img
          src="./logo-nobackground-1000.png"
          alt="Your Logo"
          className="logo"
        />
      </Link>
    </div>
    <div className="address-container">
      <p>Abdul Saboor Ataei</p>
      <p>Bruchwiesenweg 1</p>
      <p>76571 Gaggenau</p>
    </div>
    <div className="contact-container">
      <p>Mobil: +49 (163) 111-3232</p>
      <p>info@top-autoaufbereitung.com</p>
      <p>www.top-autoaufbereitung.com</p>
    </div>
    <div className="legal-and-social">
      <div className="social-icons">
        <a href="your-facebook-link" target="_blank" rel="noreferrer">
          <FaFacebookF size={20} />
        </a>
        <a href="your-instagram-link" target="_blank" rel="noreferrer">
          <FaInstagram size={20} />
        </a>
      </div>
      <div className="legal-container">
        <Link to="/impressum">Impressum</Link>
        <br />
        <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
      </div>
    </div>
  </div>
);

export default Footer;
