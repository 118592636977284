import basisImg from "../assets/packages/andre-tan-pRppMPh4Zho-unsplash.jpg";
import premiumImg from "../assets/packages/alwin-sunny-gaP0lpKZJ54-unsplash.jpg";
import exklusivImg from "../assets/packages/joshua-aragon-8vf8d5CVicw-unsplash.jpg";
import ultimativeImg from "../assets/packages/mostafa-jamei-SbfRkoqjKeI-unsplash.jpg";

import lkwInnenImg from "../assets/autotypes/brian-stalter-arotxe540N4-unsplash.jpg";
import wohnmobilPolierenImg from "../assets/autotypes/roadpass-XLW1d4eWVy0-unsplash.jpg";
import wohnmobilInnenImg from "../assets/autotypes/clay-banks-g4oQpCaX6lw-unsplash.jpg";
import lkwInnenAussenImg from "../assets/autotypes/gabriel-santos-GBVDilE8yvI-unsplash.jpg";

const lkwPackages = [
  {
    title: "Innenpflege",
    img: lkwInnenImg,
    price: {
      Price: "400,00 €",
    },
    description:
      "Beinhaltet eine gründliche Innenreinigung, um Ihren LKW frisch und sauber zu halten. Wir reinigen und pflegen Armaturen, Sitzbezüge und Innenraumverkleidungen, um einen sauberen und komfortablen Fahrerlebnis zu gewährleisten.",
  },
  {
    title: "Innen und Außen",
    img: lkwInnenAussenImg,
    price: {
      Price: "600,00 €",
    },
    description:
      "Bietet alles, was im Innenpflegepaket enthalten ist, sowie eine gründliche Außenreinigung. Dieses Paket stellt sicher, dass Ihr LKW sowohl innen als auch außen in einwandfreiem Zustand ist.",
  },
];

const wohnmobilPackages = [
  {
    title: "Innenpflege",
    img: wohnmobilInnenImg,
    price: {
      Price: "195,00 €",
    },
    description:
      "Beinhaltet eine intensive Reinigung des Innenraums Ihres Wohnmobils. Alle Oberflächen werden gründlich gereinigt und aufgefrischt, um ein sauberes und komfortables Wohn- und Fahrerlebnis zu gewährleisten.",
  },
  {
    title: "Innen und Außen",
    img: wohnmobilPolierenImg,
    price: {
      Price: "290,00 €",
    },
    description:
      "Bietet alles, was im Innenpflegepaket enthalten ist, sowie eine gründliche Außenreinigung. Dieses Paket stellt sicher, dass Ihr Wohnmobil sowohl innen als auch außen in einwandfreiem Zustand ist.",
  },
  {
    title: "Professionelle Frontbereich-Politur",
    img: wohnmobilPolierenImg,
    price: {
      Price: "295,00 €",
    },
    description:
      "Unser spezielles Paket zur Politur des Frontbereichs lässt die Außenfront Ihres Wohnmobils in neuem Glanz erstrahlen. Dieses Paket bietet nicht nur einen beeindruckenden Glanz, sondern schützt auch den Frontbereich effektiv vor den Widrigkeiten des Wetters, damit Sie Ihre Reise in vollen Zügen genießen können.",
  },
];

const pkwVanSuvPackages = [
  {
    title: "Basishygiene-Paket",
    img: basisImg,
    price: {
      PKW: "120,00 €",
      "SUV/VAN": "180,00 €",
    },
    description:
      "Beinhaltet eine gründliche Außenwäsche und Felgenpflege, die Ihr Auto von Schmutz und Ablagerungen befreit. Dieses Paket stellt die grundlegende Sauberkeit Ihres Autos sicher.",
  },
  {
    title: "Premium-Pflege-Paket",
    img: premiumImg,
    price: {
      PKW: "180,00 €",
      "SUV/VAN": "240,00 €",
    },
    description:
      "Beinhaltet alles im Basishygiene-Paket sowie eine gründliche Innenraumpflege. Ihre Armaturen und Sitzbezüge werden gereinigt und aufgefrischt, was für ein sauberes und komfortables Fahrerlebnis sorgt.",
  },
  {
    title: "Exklusiv-Pflege-Paket",
    img: exklusivImg,
    price: {
      PKW: "210,00 €",
      "SUV/VAN": "270,00 €",
    },
    description:
      "Zusätzlich zu den Leistungen im Premium-Pflege-Paket beinhaltet dieses Paket eine Motorwäsche. Dieses Paket garantiert, dass Ihr Auto sowohl äußerlich als auch in Bezug auf den Motorraum in bestmöglichem Zustand ist.",
  },
  {
    title: "Ultimative Intensivpflege",
    img: ultimativeImg,
    price: {
      PKW: "295,00 €",
      "SUV/VAN": "360,00 €",
    },
    description:
      "Unser bestes Paket bietet alle oben genannten Dienstleistungen sowie eine umfassende Lederpflege. Dieses Paket gewährleistet, dass jedes Detail Ihres Autos, einschließlich der Ledersitze, gepflegt und in hervorragendem Zustand ist.",
  },
];

const overviewPackages =
  "Willkommen auf unserer Paketseite! Wir verstehen, dass jedes Fahrzeug unterschiedliche Bedürfnisse hat. Deshalb haben wir eine Reihe von Pflegepaketen entwickelt, die auf die einzigartigen Anforderungen Ihres Autos zugeschnitten sind. Egal, ob Sie eine einfache Außenwäsche oder eine intensive Innen- und Außenpflege wünschen, wir haben das perfekte Paket für Sie. Entdecken Sie unsere maßgeschneiderten Pakete unten und wählen Sie dasjenige aus, das am besten zu Ihren Bedürfnissen passt. Bei Fragen stehen wir Ihnen gerne zur Verfügung. Wir freuen uns darauf, Ihr Auto zum Strahlen zu bringen!";

export { overviewPackages, pkwVanSuvPackages, lkwPackages, wohnmobilPackages };
