import React from "react";
import "./ContactPage.css";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <div className="contact-header">Wir hören gerne von Ihnen!</div>
      <div className="contact-info">
        <div className="info-card">
          <div className="info-header">Address</div>
          <div className="info-text">Bruchwiesenweg 1</div>
          <div className="info-text">76571 Gaggenau</div>
        </div>
        <div className="info-container">
          <div className="info-item">
            <div className="info-header">Name</div>
            <div className="info-text">Abdul Saboor Ataei</div>
          </div>
          <div className="info-item">
            <div className="info-header">Email</div>
            <div className="info-text">info@top-autoaufbereitung.com</div>
          </div>
          <div className="info-item">
            <div className="info-header">Phone Number</div>
            <div className="info-text">+49 (163) 111-3232</div>
          </div>
        </div>
      </div>
      <div className="map-container">
        <iframe
          title="location"
          src="https://maps.google.com/maps?q=Bruchwiesenweg%201,76571%20Gaggenau&t=&z=13&ie=UTF8&iwloc=&output=embed"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
